<template>
  <FullCalendar :events="bookings" :options="options" />
</template>

<script>
import '@fullcalendar/core/main.min.css'
import '@fullcalendar/daygrid/main.min.css'
import '@fullcalendar/timegrid/main.min.css'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { formatDate } from '@/helpers/date'
import FullCalendar from 'primevue/fullcalendar'

export default {
  components: { FullCalendar },
  props: {
    bookings: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['click', 'fetch', 'create'],
  setup(props, { emit }) {
    const store = useStore()
    const options = ref({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      defaultDate: formatDate({ date: new Date() }),
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      editable: false,
      defaultView: store.state.calendarView
        ? store.state.calendarView
        : 'timeGridWeek',
      height: 'auto',
      eventClick: e => {
        //handle date click
        emit('click', e)
      },
      datesRender: e => {
        // get bookings between start and end dates for current view
        const start = e.view.activeStart
        const end = e.view.activeEnd
        emit('fetch', { start, end })

        // save view to vuex
        localStorage.setItem('calendarView', e.view.type)
        store.commit('storeCalendarView', e.view.type)
      },
      dateClick: e => {
        emit('create', e.date)
      }
    })

    return { options }
  }
}
</script>

<style lang="scss" scoped>
.fc ::v-deep(.fc-event) {
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}
.fc ::v-deep(.fc-today) {
  background: #eee;
  .fc-day-number {
    font-weight: bold;
  }
}
@media screen and (max-width: 40em) {
  .fc ::v-deep(.fc-toolbar) {
    h2 {
      font-size: 1rem;
      padding: 0 0.25rem;
    }
  }
  .fc ::v-deep(.fc-view-container) {
    th {
      font-size: 0.9rem;
    }
  }
}
</style>
