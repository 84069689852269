import { ref } from 'vue'
import { db } from '@/services/firebase'
import { useStore } from 'vuex'
import { getPermissions } from '@/helpers/permissions'

export function useGetProjects() {
  const store = useStore()
  const { isClientAdminOnly } = getPermissions()
  const user = store.getters.user

  const projects = ref([])
  function getProjects() {
    db.ref('projects')
      .orderByChild('active')
      .equalTo(true)
      .once('value')
      .then(data => {
        const projectList = []
        data.forEach(function (snapshot) {
          const row = snapshot.val()
          row.id = snapshot.key
          if (isClientAdminOnly.value) {
            // only show project that is assigned to this client admin
            const projectId = user.projects.length ? user.projects[0] : ''
            if (row.id === projectId) projectList.push(row)
          } else {
            projectList.push(row)
          }
        })

        projects.value = projectList
          .map(row => {
            return { label: row.name, value: row.id }
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      })
  }

  return { projects, getProjects }
}
