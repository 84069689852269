<template>
  <form v-if="editMode" @submit.prevent="submit">
    <div class="p-field">
      <AutoComplete
        :suggestions="filteredTestees"
        @complete="searchTestees($event)"
        @item-select="selectTestee($event)"
        placeholder="Email"
        field="email"
        class="testee-email"
        v-model="formData.email"
        @focus="!testees.length ? $emit('testees') : null"
        @blur="validation.formData.email.$touch()"
        :class="{ 'p-invalid': validation.formData.email.$error }"
      />
      <BaseValidationErrors
        :field="validation.formData.email"
        :rules="['required']"
      />
    </div>
    <div class="p-field">
      <InputText
        @keyup="submitOnKeyUp"
        placeholder="First Name"
        type="text"
        class="testee-first-name"
        v-model="formData.firstName"
        @blur="validation.formData.firstName.$touch()"
        :class="{ 'p-invalid': validation.formData.firstName.$error }"
      />
      <BaseValidationErrors
        :field="validation.formData.firstName"
        :rules="['required']"
      />
    </div>
    <div class="p-field">
      <InputText
        @keyup="submitOnKeyUp"
        placeholder="Last Name"
        type="text"
        class="testee-last-name"
        v-model="formData.lastName"
        @blur="validation.formData.lastName.$touch()"
        :class="{ 'p-invalid': validation.formData.lastName.$error }"
      />
      <BaseValidationErrors
        :field="validation.formData.lastName"
        :rules="['required']"
      />
    </div>
    <div class="p-field">
      <Calendar
        :showIcon="true"
        @date-select="submitOnKeyUp"
        placeholder="DOB"
        class="testee-dob"
        v-model="formData.dob"
        :monthNavigator="true"
        :yearNavigator="true"
        :yearRange="`1900:${thisYear}`"
        :manualInput="false"
        dateFormat="yy-mm-dd"
      />
    </div>
    <div class="p-field">
      <InputText
        @keyup="submitOnKeyUp"
        placeholder="Mobile Phone Number"
        class="testee-mobile"
        v-model="formData.mobile"
        @blur="validation.formData.mobile.$touch()"
        :class="{ 'p-invalid': validation.formData.mobile.$error }"
      />
      <BaseValidationErrors
        :field="validation.formData.mobile"
        :rules="['required']"
      />
    </div>
    <div class="p-field">
      <Textarea
        @keyup="submitOnKeyUp"
        placeholder="Comments"
        class="testee-comments"
        v-model="formData.comments"
      />
    </div>
    <Button
      v-if="showButton"
      type="submit"
      class="p-mb-0 save-testee p-button-sm p-button-outlined"
      :disabled="validation.$invalid"
      label="Save Patient"
    />
  </form>
  <div class="testee-info" v-else>
    <p>
      <strong>Email Address:</strong> {{ formData.email }}<br />
      <strong>First Name:</strong> {{ formData.firstName }}<br />
      <strong>Last Name:</strong> {{ formData.lastName }}<br />
      <strong>DOB:</strong> {{ formData.dob }}<br />
      <strong>Mobile:</strong> {{ formData.mobile }}<br />
      <strong>Comments:</strong> {{ formData.comments }}
    </p>
    <Button
      @click="toggle"
      class="p-mb-0 edit-testee p-button-sm p-button-outlined"
      label="Edit Patient"
    />
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import AutoComplete from 'primevue/autocomplete'
import Calendar from 'primevue/calendar'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { formatDate } from '@/helpers/date'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'

export default {
  name: 'TesteeForm',
  props: {
    counter: {
      type: Number,
      default: 0
    },
    testees: {
      type: Array,
      default: () => []
    },
    showButton: {
      type: Boolean,
      default: false
    },
    populateWith: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    InputText,
    Textarea,
    Button,
    AutoComplete,
    Calendar,
    BaseValidationErrors
  },
  emits: ['submitted', 'testees'],
  setup(props, { emit }) {
    const editMode = ref(true)
    const formData = ref({
      id: null,
      firstName: '',
      lastName: '',
      dob: '',
      mobile: '',
      email: '',
      comments: '',
      status: 'Pending',
      result: 'Pending'
    })

    const rules = {
      formData: {
        firstName: { required },
        lastName: { required },
        mobile: { required },
        email: { required }
      }
    }
    const validation = useVuelidate(rules, { formData })

    watchEffect(() => {
      if (props.populateWith) {
        const data = props.populateWith
        data.dob = formatDate({ date: data.dob })
        formData.value = data
      }
    })

    const thisYear = new Date().getFullYear()

    function submitOnKeyUp() {
      // emit on keyup to avoid having to click save button for each patient
      if (!props.showButton && !validation.value.$invalid) {
        emit('submitted', formData.value, props.counter)
      }
    }

    function toggle() {
      editMode.value = !editMode.value
    }

    function submit() {
      validation.value.$touch()
      if (!validation.value.$invalid) {
        toggle()
        emit('submitted', formData.value, props.counter)
      }
    }

    const filteredTestees = ref([])

    function searchTestees(event) {
      if (!event.query.trim().length) {
        filteredTestees.value = [...props.testees]
      } else {
        filteredTestees.value = props.testees
          .filter(user => {
            if (!user.email) return false
            return user.email
              .toLowerCase()
              .startsWith(event.query.toLowerCase())
          })
          .sort((a, b) => a.email.localeCompare(b.email))
      }
    }

    function selectTestee(event) {
      formData.value.id = event.value.id
      formData.value.email = event.value.email
      formData.value.firstName = event.value.firstName
      formData.value.lastName = event.value.lastName
      formData.value.dob = event.value.dob
      formData.value.mobile = event.value.mobile

      if (!props.showButton) submitOnKeyUp()
    }

    return {
      editMode,
      formData,
      submit,
      toggle,
      filteredTestees,
      searchTestees,
      selectTestee,
      validation,
      submitOnKeyUp,
      thisYear
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
</style>
