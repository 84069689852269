import { firestore } from '@/services/firebase'
import { formatDate } from '@/helpers/date'

function sendMail(email) {
  firestore.collection('mail').add(email)
}

// ADD BY ROLE
async function addRecipients(roles) {
  const users = []
  if (Array.isArray(roles)) {
    const query = await firestore
      .collection('users')
      .where('roles', 'array-contains-any', roles)
      .get()

    query.forEach(doc => {
      const row = doc.data()
      row.id = doc.id
      users.push(row)
    })
  }
  return users
}

export async function onBookingCreate(booking) {
  // fetch all Booking admins
  let admins = await addRecipients(['Booking Admin'])
  admins = admins.map(row => row.email)

  // send to person who created event
  const emails = admins.concat([booking.user.email])

  // add url for hyperlink
  // convert date to string format
  booking.url = process.env.VUE_APP_URL
  booking.dateString = formatDate({
    date: new Date(booking.datetime),
    string: true
  })

  // remove duplicate emails using Set()
  sendMail({
    bcc: [...new Set(emails)],
    template: {
      name: 'BOOKING_CREATE',
      data: booking
    }
  })
}

// Individual Email to Patients on booking confirmation
export function patientConfirmation(booking, patient) {
  const data = { ...booking }
  // filter out other patients
  data.testees = []
  data.testees.push(patient)
  // add url for hyperlink
  data.url = process.env.VUE_APP_URL
  // remove lab
  data.lab = null
  // set datetime to the patient's booking time
  data.datetime = patient.datetime ? patient.datetime : data.datetime
  // convert date to string format
  data.dateString = formatDate({
    date: data.datetime.toDate(),
    string: true
  })

  sendMail({
    to: patient.email,
    template: {
      name: 'BOOKING_CONFIRMATION',
      data
    }
  })
}

export async function onBookingConfirmation(booking) {
  // send to patients
  booking.testees.forEach(row => {
    patientConfirmation(booking, row)
  })

  // fetch all client admins
  let admins = await addRecipients(['Booking Admin'])
  admins = admins.map(row => row.email)

  // get collectors
  const collectors = booking.testers.map(row => row.email)

  // merge arrays
  const emails = [].concat.apply([], [admins, collectors, booking.user.email])

  // add url for hyperlink
  // convert date to string format
  booking.url = process.env.VUE_APP_URL
  booking.dateString = formatDate({
    date: booking.datetime.toDate(),
    string: true
  })

  // remove duplicate emails using Set()
  sendMail({
    bcc: [...new Set(emails)],
    template: {
      name: 'BOOKING_CONFIRMATION',
      data: booking
    }
  })
}

// Individual Email to Patients with negative results
export function patientNegative(booking, patient) {
  const data = { ...booking }

  // add url for hyperlink
  // convert date to string format
  data.url = process.env.VUE_APP_URL
  // set datetime to the patient's booking time
  data.datetime = patient.datetime ? patient.datetime : data.datetime
  // convert date to string
  data.dateString = formatDate({
    date: data.datetime.toDate(),
    string: true
  })

  sendMail({
    to: patient.email,
    template: {
      name: 'PATIENT_NEGATIVE',
      data
    }
  })
}

// Individual Email to Patients with negative results
export async function bookingPositive(booking) {
  const data = { ...booking }

  // filter out other patients
  data.testees = data.testees.filter(row => {
    return (
      Object.prototype.hasOwnProperty.call(row, 'result') &&
      row.result === 'Positive'
    )
  })

  // fetch all client admins
  const admins = await addRecipients(['Medical Admin'])
  const emails = admins.map(row => row.email)

  // add url for hyperlink
  // convert date to string format
  data.url = process.env.VUE_APP_URL
  data.dateString = formatDate({
    date: data.datetime.toDate(),
    string: true
  })

  sendMail({
    to: emails,
    template: {
      name: 'BOOKING_POSITIVE',
      data
    }
  })
}

// Send to client admins on booking close
export async function onBookingClose(booking) {
  // positive count
  let positives = 0
  // Send individual emails to Patients with negative results
  booking.testees.forEach(row => {
    if (row.result && row.result === 'Negative') patientNegative(booking, row)

    if (
      row.result &&
      row.result === 'Positive' &&
      row.status &&
      row.status === 'Testing Complete'
    )
      positives++
  })

  // send email to Medical Admin with list of Positive Results
  if (positives) bookingPositive(booking)

  // fetch all Booking admins
  let admins = await addRecipients(['Booking Admin'])
  admins = admins.map(row => row.email)

  // send to person who created event
  const emails = admins.concat([booking.user.email])

  // add url for hyperlink
  // convert date to string format
  booking.url = process.env.VUE_APP_URL
  booking.dateString = formatDate({
    date: booking.datetime.toDate(),
    string: true
  })

  // remove duplicate emails using Set()
  sendMail({
    bcc: [...new Set(emails)],
    template: {
      name: 'BOOKING_CLOSE',
      data: booking
    }
  })
}
