<template>
  <div v-if="editMode" class="p-grid p-input-filled">
    <div class="p-col-12 p-sm-6">
      <form v-if="canUpdateBooking" @submit.prevent="confirm">
        <div class="p-field">
          <InputText
            placeholder="Title"
            type="text"
            class="production-name"
            v-model="formData.name"
            @blur="validation.formData.name.$touch()"
            :class="{ 'p-invalid': validation.formData.name.$error }"
          />
          <BaseValidationErrors
            :field="validation.formData.name"
            :rules="['required']"
          />
        </div>
        <div class="p-field" v-if="canUpdateProject">
          <Dropdown
            v-model="formData.project"
            :options="projects"
            optionLabel="label"
            optionValue="value"
            placeholder="Project"
          />
        </div>
        <div class="p-field" v-if="canEditBookingTime">
          <Calendar
            :showIcon="true"
            placeholder="Testing Date/Time"
            :showTime="true"
            :showSeconds="true"
            class="testing-datetime"
            v-model="formData.datetime"
            :manualInput="false"
            @blur="validation.formData.datetime.$touch()"
            :class="{ 'p-invalid': validation.formData.datetime.$error }"
          />
          <BaseValidationErrors
            :field="validation.formData.datetime"
            :rules="['required']"
          />
          <small v-if="formData.datetime && !isAvailable" class="p-invalid">
            Time conflicts with another booking
          </small>
        </div>
        <div class="p-field p-mt-3">
          <div class="radio-group">
            <RadioButton
              id="testing-type-group"
              name="testingType"
              class="testing-type-group"
              v-model="formData.type"
              value="Group Testing"
            />
            <label for="testing-type-group">Group Testing</label>
            <RadioButton
              id="testing-type-individual"
              name="testingType"
              class="testing-type-individual"
              v-model="formData.type"
              value="Individual Testing"
            />
            <label for="testing-type-individual">Individual Testing</label>
            <BaseValidationErrors
              :field="validation.formData.type"
              :rules="['required']"
            />
          </div>
        </div>
        <div class="p-field-checkbox p-mt-3">
          <Checkbox id="binary" v-model="formData.covid" :binary="true" />
          <label for="binary">COVID risk</label>
        </div>
        <Button
          type="submit"
          class="save-booking p-button-sm p-button-outlined"
          label="Save Booking"
          icon="pi pi-save"
          :disabled="validation.$invalid"
        />
        <Button
          class="p-ml-2 cancel-booking p-button-sm p-button-outlined p-button-danger"
          label="Cancel"
          icon="pi pi-times"
          @click="toggle"
        />
      </form>
      <ConfirmDialog
        :display="displayConfirmation"
        @confirm="saveBooking"
        @display="toggleConfirmation"
        attention="This date and time conflicts with another booking!"
        message="Are you sure you want to confirm the booking?"
        note="The time requested has been requested by another project and therefore it may not be available as requested. Please choose an open time or wait for confirmation from PMO following your request."
      />
    </div>
  </div>
  <ul v-else>
    <li>
      <h3 class="p-mt-0">{{ booking.name }}</h3>
    </li>
    <li v-if="booking.project"><b>Project:</b> {{ booking.project.name }}</li>
    <li v-if="datetime"><b>Date:</b> {{ datestring }}</li>
    <li v-if="booking.duration">
      <b>Duration:</b> {{ booking.duration }} mins
    </li>
    <li><b>Type:</b> {{ booking.type }}</li>
    <li><b>Number of Patients:</b> {{ booking.amount }}</li>
    <li><b>Status:</b> {{ booking.status }}</li>
    <li v-if="booking.covid">
      <b>Covid risk? </b>
      <i class="pi pi-check"></i>
    </li>
    <li v-if="booking.conflict">
      <b style="color: red">Conflict </b>
      <i class="pi pi-exclamation-triangle"></i>
    </li>
    <li v-if="save">
      <Button
        v-if="canUpdateBooking"
        @click="onEditModeClick"
        class="edit-booking p-button-sm p-button-outlined"
        label="Edit Booking"
        icon="pi pi-pencil"
      />
    </li>
  </ul>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { formatDate } from '@/helpers/date'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import RadioButton from 'primevue/radiobutton'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { getPermissions } from '@/helpers/permissions'
import { calculateTimes, calculateMinutes } from '@/helpers/patients'
import { checkAvailability } from '@/composables/useCreateBooking'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    InputText,
    Button,
    Calendar,
    Dropdown,
    RadioButton,
    Checkbox,
    BaseValidationErrors,
    ConfirmDialog
  },
  props: {
    booking: {
      type: Object,
      default: new Object()
    },
    save: {
      type: Boolean,
      default: false
    },
    projects: {
      type: Array,
      default() {
        return []
      }
    },
    canEditBookingTime: {
      type: Boolean,
      default: true
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const { canUpdateBooking, canUpdateProject } = getPermissions()
    const formData = ref({
      datetime: null,
      name: null,
      project: null,
      type: null,
      covid: false
    })

    const rules = {
      formData: {
        datetime: { required },
        name: { required },
        type: { required }
      }
    }
    const validation = useVuelidate(rules, { formData })

    const editMode = ref(false)
    const datetime = computed(() => {
      if (props.booking.datetime)
        return formatDate({
          date: props.booking.datetime.toDate(),
          input: true
        })
      return false
    })
    const datestring = computed(() => {
      if (props.booking.datetime)
        return formatDate({
          date: props.booking.datetime.toDate(),
          string: true
        })
      return false
    })

    function toggle() {
      editMode.value = !editMode.value
    }

    function onEditModeClick() {
      const { name, project, type, covid } = props.booking

      formData.value = {
        datetime: props.booking.datetime.toDate(),
        name,
        project: project.id,
        type,
        covid
      }
      toggle()
    }

    const isAvailable = ref(true)
    const displayConfirmation = ref(false)

    function saveBooking() {
      displayConfirmation.value = false
      validation.value.$touch()
      if (!validation.value.$invalid) {
        toggle()
        const data = { ...formData.value, conflict: !isAvailable.value }
        if (!(data.datetime instanceof Date))
          data.datetime = new Date(data.datetime)

        data.project = props.projects
          .map(row => {
            return { id: row.value, name: row.label }
          })
          .find(row => row.id === data.project)

        const { datetime } = data
        const { testers, testees } = props.booking

        data.testees = calculateTimes({ datetime, testers, testees })
        data.duration = calculateMinutes({ testers, testees })

        emit('submitted', data)
      }
    }

    watch(
      () => formData.value.datetime,
      async newVal => {
        const amount = props.booking.amount
        const id = props.booking.id
        isAvailable.value = await checkAvailability(newVal, amount, id)
        // dont send request if new and old are same day
      }
    )
    watch(
      () => formData.value.amount,
      async newVal => {
        const datetime = formData.value.datetime
        const id = props.booking.id
        isAvailable.value = await checkAvailability(datetime, newVal, id)
        // dont send request if new and old are same day
      }
    )

    function confirm() {
      if (isAvailable.value) saveBooking()
      else displayConfirmation.value = true
    }
    function toggleConfirmation(value) {
      displayConfirmation.value = value
    }

    return {
      formData,
      editMode,
      datetime,
      datestring,
      onEditModeClick,
      saveBooking,
      toggle,
      validation,
      canUpdateBooking,
      canUpdateProject,
      confirm,
      displayConfirmation,
      isAvailable,
      toggleConfirmation
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
ul {
  margin: 0 0 1rem;
  padding-left: 0;
  list-style: none;
  li {
    margin-bottom: 0.5rem;
  }
}
h3 {
  margin-bottom: 0.5rem;
}
</style>
