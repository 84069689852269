<template>
  <form @submit.prevent="confirm">
    <div class="p-mb-3 p-input-filled">
      <div class="p-field">
        <Calendar
          :showIcon="true"
          v-model="formData.datetime"
          placeholder="Testing Date/Time"
          :showTime="true"
          :showSeconds="true"
          class="testing-datetime"
          :manualInput="false"
          dateFormat="yy-mm-dd"
        />
        <small v-if="!isAvailable" class="p-invalid">
          Time conflicts with another booking
        </small>
      </div>
      <div class="p-field">
        <InputText
          placeholder="Title"
          type="text"
          class="production-name"
          v-model="formData.name"
          @blur="validation.formData.name.$touch()"
          :class="{ 'p-invalid': validation.formData.name.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.name"
          :rules="['required']"
        />
      </div>
      <div class="p-field">
        <Dropdown
          v-model="formData.project"
          :options="projects"
          optionLabel="label"
          optionValue="value"
          placeholder="Project"
          @blur="validation.formData.project.$touch()"
          :class="{ 'p-invalid': validation.formData.project.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.project"
          :rules="['required']"
        />
      </div>
      <div class="p-field">
        <InputNumber
          showButtons
          placeholder="Number of people requiring testing"
          class="number-of-people"
          v-model="formData.amount"
          @blur="validation.formData.amount.$touch()"
          :class="{ 'p-invalid': validation.formData.amount.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.amount"
          :rules="['required', 'integer', 'minValue']"
        />
      </div>
      <div class="radio-group p-mt-3">
        <RadioButton
          id="testing-type-group"
          name="testingType"
          class="testing-type-group"
          v-model="formData.type"
          @blur="validation.formData.type.$touch()"
          value="Group Testing"
        />
        <label for="testing-type-group">Group Testing</label>
        <RadioButton
          id="testing-type-individual"
          name="testingType"
          class="testing-type-individual"
          v-model="formData.type"
          @blur="validation.formData.type.$touch()"
          value="Individual Testing"
        />
        <label for="testing-type-individual">Individual Testing</label>
        <BaseValidationErrors
          :field="validation.formData.type"
          :rules="['required']"
        />
      </div>
      <div class="p-field-checkbox p-mt-3">
        <Checkbox id="binary" v-model="formData.covid" :binary="true" />
        <label for="binary">COVID risk</label>
      </div>
      <h3>Testing Location</h3>
      <div class="p-field">
        <AutoComplete
          :suggestions="filteredLocations"
          @complete="searchLocations($event)"
          @item-select="selectLocation($event)"
          placeholder="Name/Description"
          field="name"
          class="testing-name"
          v-model="formData.location.name"
          @focus="!locations.length ? $emit('locations') : null"
          @blur="validation.formData.location.name.$touch()"
          :class="{ 'p-invalid': validation.formData.location.name.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.location.name"
          :rules="['required']"
        />
      </div>
      <div class="p-field">
        <InputText
          placeholder="Street Address"
          type="text"
          class="testing-address"
          v-model="formData.location.address"
          @blur="validation.formData.location.address.$touch()"
          :class="{ 'p-invalid': validation.formData.location.address.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.location.address"
          :rules="['required']"
        />
      </div>
      <div class="p-field">
        <InputText
          placeholder="City"
          type="text"
          class="testing-city"
          v-model="formData.location.city"
          @blur="validation.formData.location.city.$touch()"
          :class="{ 'p-invalid': validation.formData.location.city.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.location.city"
          :rules="['required']"
        />
      </div>
      <div class="p-field">
        <InputText
          placeholder="Province"
          type="text"
          class="testing-province"
          v-model="formData.location.province"
          @blur="validation.formData.location.province.$touch()"
          :class="{ 'p-invalid': validation.formData.location.province.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.location.province"
          :rules="['required']"
        />
      </div>
      <div class="p-field">
        <InputText
          placeholder="Postal Code"
          type="text"
          class="testing-postal-code"
          v-model="formData.location.postalCode"
          @blur="validation.formData.location.postalCode.$touch()"
          :class="{
            'p-invalid': validation.formData.location.postalCode.$error
          }"
        />
        <BaseValidationErrors
          :field="validation.formData.location.postalCode"
          :rules="['required']"
        />
      </div>
      <div v-if="numberOfTestees > 0">
        <h3>Patients</h3>
        <ol class="p-mb-0 p-pl-4">
          <li
            v-for="testee in numberOfTestees"
            :key="testee"
            :class="'testee' + testee"
          >
            <TesteeForm
              :testees="testees"
              :counter="testee"
              @submitted="addTestee"
              @testees="getTestees"
            />
          </li>
        </ol>
      </div>
    </div>
    <Button
      type="submit"
      class="submit"
      :disabled="disabled || validation.$invalid"
      label="Request Booking"
    />
  </form>
  <ConfirmDialog
    :display="displayConfirmation"
    @confirm="submit"
    @display="toggleConfirmation"
    attention="This date and time conflicts with another booking!"
    message="Are you sure you want to confirm the booking?"
    note="The time requested has been requested by another project and therefore it may not be available as requested. Please choose an open time or wait for confirmation from PMO following your request."
  />
</template>

<script>
import { ref, computed, watchEffect, watch } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import AutoComplete from 'primevue/autocomplete'
import InputNumber from 'primevue/inputnumber'
import ConfirmDialog from '@/components/ConfirmDialog'
import TesteeForm from './TesteeForm.vue'
import useVuelidate from '@vuelidate/core'
import { required, integer, minValue } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { checkAvailability } from '@/composables/useCreateBooking'

export default {
  name: 'BookingForm',
  components: {
    Button,
    InputText,
    RadioButton,
    Checkbox,
    Dropdown,
    Calendar,
    AutoComplete,
    InputNumber,
    TesteeForm,
    BaseValidationErrors,
    ConfirmDialog
  },
  props: {
    locations: {
      type: Array,
      default() {
        return []
      }
    },
    date: {
      type: Date,
      default: new Date()
    },
    disabled: {
      type: Boolean,
      default: false
    },
    testees: {
      type: Array,
      default() {
        return []
      }
    },
    projects: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['locations', 'testees', 'submitted', 'projects'],
  setup(props, { emit }) {
    const formData = ref({
      datetime: null,
      duration: null,
      name: null,
      type: null,
      amount: null,
      location: {
        id: null,
        name: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        active: true
      },
      testees: [],
      testerIds: [],
      status: 'Requested',
      lab: null,
      testers: [],
      covid: false,
      project: null
    })

    const rules = {
      formData: {
        name: { required },
        project: { required },
        type: { required },
        amount: { required, integer, minValue: minValue(0) },
        location: {
          name: { required },
          address: { required },
          city: { required },
          province: { required },
          postalCode: { required }
        }
      }
    }
    const validation = useVuelidate(rules, { formData })

    const numberOfTestees = computed(() => {
      if (parseInt(formData.value.amount))
        return parseInt(formData.value.amount)
      else return 0
    })

    const isAvailable = ref(true)

    watchEffect(async () => {
      // prepopulate based on calendar
      if (!formData.value.datetime && props.date) {
        formData.value.datetime = props.date

        const amount = formData.value.amount
        isAvailable.value = await checkAvailability(props.date, amount)
      }
    })

    function addTestee(testee, counter) {
      formData.value.testees[counter - 1] = testee
    }

    const filteredLocations = ref([])

    function searchLocations(event) {
      if (!event.query.trim().length) {
        filteredLocations.value = [...props.locations]
      } else {
        filteredLocations.value = props.locations
          .filter(location => {
            if (!location.name) return false
            return location.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase())
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    }

    function selectLocation(event) {
      formData.value.location.id = event.value.id
      formData.value.location.name = event.value.name
      formData.value.location.address = event.value.address
      formData.value.location.city = event.value.city
      formData.value.location.province = event.value.province
      formData.value.location.postalCode = event.value.postalCode
    }

    function submit() {
      validation.value.$touch()
      if (!validation.value.$invalid) {
        // save entire project object to the booking
        const data = { ...formData.value, conflict: !isAvailable.value }
        data.project = props.projects
          .map(row => {
            return { id: row.value, name: row.label }
          })
          .find(row => row.id === data.project)
        emit('submitted', data)
      }
    }

    watch(
      () => formData.value.datetime,
      async newVal => {
        const amount = formData.value.amount
        isAvailable.value = await checkAvailability(newVal, amount)
        // dont send request if new and old are same day
      }
    )
    watch(
      () => formData.value.amount,
      async newVal => {
        const datetime = formData.value.datetime
        isAvailable.value = await checkAvailability(datetime, newVal)
        // dont send request if new and old are same day
      }
    )

    const displayConfirmation = ref(false)
    function confirm() {
      validation.value.$touch()
      if (!validation.value.$invalid) {
        if (isAvailable.value) submit()
        else displayConfirmation.value = true
      }
    }
    function toggleConfirmation(value) {
      displayConfirmation.value = value
    }

    function getTestees() {
      emit('testees')
    }

    emit('projects')

    return {
      formData,
      filteredLocations,
      numberOfTestees,
      addTestee,
      searchLocations,
      selectLocation,
      validation,
      getTestees,
      isAvailable,
      submit,
      confirm,
      displayConfirmation,
      toggleConfirmation
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
ol {
  li {
    margin-top: 1rem;
  }
}
</style>
