<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Calendar</h1>
  <Calendar
    :bookings="bookings"
    @click="showBooking"
    @fetch="getBookings"
    @create="showForm"
  />
  <h4 class="p-mb-0">Legend</h4>
  <ul class="p-mt-2 p-pl-0 legend">
    <li v-for="row in legend" :key="row.label" class="legend-item p-my-1">
      <span
        class="customer-badge"
        :style="`background:${row.bg}; color:${row.text}`"
      >
        {{ row.label }}
      </span>
    </li>
  </ul>
  <Dialog
    header="Booking Info"
    v-model:visible="displayBooking"
    :modal="true"
    :dismissableMask="true"
  >
    <BookingInfo
      :booking="event.extendedProps"
      :projects="projects"
      :save="false"
    />
    <Button
      icon="pi pi-pencil"
      label="Update"
      @click="$router.push({ name: 'UpdateBooking', params: { id: event.id } })"
    />
    <Button
      v-if="canDeleteBooking"
      class="p-button-danger p-ml-3"
      icon="pi pi-times"
      label="Delete"
      @click="deleteDocument(event.id)"
    />
  </Dialog>
  <Dialog
    header="Booking Info"
    v-model:visible="displayForm"
    :modal="true"
    :dismissableMask="true"
  >
    <BookingForm
      @submitted="onCreateBooking"
      :locations="locations"
      :testees="testees"
      :date="selectedDate"
      :disabled="disabled"
      :projects="projects"
    />
  </Dialog>
  <ConfirmDialog
    :display="displayConfirmDelete"
    :disable="disableConfirmButton"
    @confirm="confirm"
    @display="display"
  />
</template>

<script>
import { ref, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { firestore } from '@/services/firebase'
import { formatDate } from '@/helpers/date'
import { useToast } from 'primevue/usetoast.js'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import BookingInfo from '@/components/BookingInfo'
import Calendar from '@/components/Calendar.vue'
import BookingForm from '@/components/BookingForm'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'
import { useCreateBooking } from '@/composables/useCreateBooking'
import { useDeleteDocument } from '@/composables/useDeleteDocument'
import { getPermissions } from '@/helpers/permissions'
import { useHandleError } from '@/composables/useHandleError'
import { useGetProjects } from '@/composables/useGetProjects'

export default {
  components: {
    Calendar,
    Dialog,
    Button,
    BookingInfo,
    BookingForm,
    ConfirmDialog,
    BaseBreadcrumb
  },
  setup() {
    let unsubscribe
    const store = useStore()
    const toast = useToast()
    const bookings = ref([])
    const displayBooking = ref(false)
    const displayForm = ref(false)
    const event = ref({})
    const selectedDate = ref(new Date())
    const disabled = ref(false)
    const breadcrumbs = [{ label: 'Calendar' }]
    const {
      locations,
      getLocations,
      testees,
      getTestees,
      createBooking
    } = useCreateBooking({
      redirect: false
    })
    const {
      disableConfirmButton,
      displayConfirmDelete,
      deleteDocument,
      confirmDeleteDocument
    } = useDeleteDocument()
    const { projects, getProjects } = useGetProjects()
    const {
      canCreateBooking,
      canDeleteBooking,
      canViewAllBookings,
      isClientAdminOnly
    } = getPermissions()
    const { handleError } = useHandleError()
    const dateStart = ref(null)
    const dateEnd = ref(null)

    getLocations()
    getTestees()
    getProjects()

    onUnmounted(() => {
      if (typeof unsubscribe === 'function') unsubscribe() // Stop listening to changes
    })

    const legend = [
      { label: 'Requested', bg: '#b3e5fc', text: '#23547b' },
      { label: 'Ready', bg: '#e9cfec', text: '#685879' },
      { label: 'Confirmed', bg: '#feedaf', text: '#8a5340' },
      { label: 'Testing Complete', bg: '#ffd8b2', text: '#805b36' },
      { label: 'Results Complete', bg: '#c8e6c9', text: '#256029' },
      { label: 'Closed', bg: '#ffcdd2', text: '#c63737' }
    ]

    function getColor(status) {
      return legend.find(row => row.label === status)
    }

    function getBookings({ start, end }) {
      if (typeof unsubscribe === 'function') unsubscribe()

      // store the dates so getBookings can be called again once the user data is loaded
      dateStart.value = start
      dateEnd.value = end

      const user = store.getters.user

      if (user) {
        // listen for real time updates to booking document
        const query = firestore
          .collection('bookings')
          .where('datetime', '>', start)
          .where('datetime', '<', end)

        unsubscribe = query.onSnapshot(
          querySnapshot => {
            const bookingsList = []
            querySnapshot.forEach(doc => {
              const booking = doc.data()
              if (booking.datetime) {
                let color = getColor(booking.status)
                const classNames = []
                // if insufficient permissions, only show that user's bookings
                if (!canViewAllBookings.value) {
                  //   query = query.where('testerIds', 'array-contains', user.id)
                  if (!booking.testerIds.includes(user.id)) {
                    color = { bg: '#eeeeee', text: '#999999' }
                    classNames.push('disabled')
                  }
                }
                // limit client admins to only view bookings assigned to their projects
                if (isClientAdminOnly.value) {
                  //   query = query.where(
                  //     'project.id',
                  //     '==',
                  //     user.projects.length ? user.projects[0] : ''
                  //   )
                  if (booking.project.id !== user.projects[0]) {
                    color = { bg: '#eeeeee', text: '#999999' }
                    classNames.push('disabled')
                  }
                }

                const row = {
                  id: doc.id,
                  title: booking.name,
                  start: formatDate({
                    date: booking.datetime.toDate(),
                    time: true
                  }),
                  extendedProps: booking,
                  color: color.bg,
                  textColor: color.text,
                  classNames
                }
                if (booking.duration) {
                  // setup end time
                  const duration = booking.datetime.toDate()
                  const h = parseFloat(booking.duration) * 60 * 1000
                  duration.setTime(duration.getTime() + h)

                  row.end = formatDate({
                    date: duration,
                    time: true
                  })
                }
                bookingsList.push(row)
              }
            })
            bookings.value = bookingsList
          },
          error => {
            handleError(error)
          }
        )
      }
    }

    watch(
      () => store.getters.user,
      (newVal, oldVal) => {
        if (oldVal === null)
          getBookings({ start: dateStart.value, end: dateEnd.value })
      }
    )

    function showBooking(e) {
      if (!e.event.classNames.includes('disabled')) {
        event.value = e.event
        displayBooking.value = true
        displayForm.value = false
      }
    }

    function showForm(date) {
      if (canCreateBooking.value) {
        selectedDate.value = date
        displayForm.value = true
        displayBooking.value = false
      }
    }

    async function onCreateBooking(formData) {
      disabled.value = true
      await createBooking(formData)
      displayForm.value = false
      disabled.value = false
    }

    function confirm() {
      confirmDeleteDocument('bookings')
        .then(() => {
          displayConfirmDelete.value = false
          disableConfirmButton.value = false
          displayBooking.value = false
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Successfully deleted',
            life: 3000
          })
        })
        .catch(error => {
          handleError(error)
        })
    }

    function display(value) {
      displayConfirmDelete.value = value
    }

    return {
      bookings,
      showBooking,
      displayBooking,
      displayForm,
      event,
      getBookings,
      showForm,
      locations,
      testees,
      onCreateBooking,
      selectedDate,
      displayConfirmDelete,
      deleteDocument,
      confirm,
      display,
      disableConfirmButton,
      disabled,
      canDeleteBooking,
      projects,
      getProjects,
      legend,
      breadcrumbs
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/badges.scss';
ul.legend {
  list-style: none;
}
.p-dialog-mask {
  margin-bottom: 0;
  ::v-deep(.p-dialog-header) {
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 770px) {
  .p-dialog-mask {
    ::v-deep(.p-dialog) {
      width: 456px;
    }
  }
}
</style>
