import { useToast } from 'primevue/usetoast.js'

export function useHandleError() {
  const toast = useToast()

  function handleError(err) {
    toast.add({
      severity: 'error',
      summary: `${err.name}: ${err.code}`,
      detail: `${err.message}`,
      life: 6000
    })
  }

  return { handleError }
}
