import moment from 'moment'

export const calculateTimes = ({ datetime, testers, testees }) => {
  const collectors = testers.length ? testers.length : 1
  // calculate patient times
  // book multiple patients at same time if more than 1 tester
  let i = 0
  return testees.map(row => {
    // first bookings = same time as booking.datetime
    if (i % collectors === 0 && i > 0) {
      // increment 5 mins
      datetime = moment(datetime).add(5, 'm').toDate()
    }
    i++
    return { ...row, datetime }
  })
}

export const calculateMinutes = ({ testees, testers }) => {
  let i = 0
  let minutes = 30
  const collectors = testers.length ? testers.length : 1
  testees.forEach(() => {
    i++
    if (i % collectors === 0) {
      // increment 5 mins
      minutes += 5
    }
  })

  return minutes
}
