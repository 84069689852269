import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { firestore, db } from '@/services/firebase'
import { useStore } from 'vuex'
import { onBookingCreate } from '@/helpers/email'
import { getNewKey } from '@/helpers/firebase'
import { formatDate, getUTC } from '@/helpers/date'
import moment from 'moment'

export function useCreateBooking({ redirect }) {
  const locations = ref([])

  function getLocations() {
    db.ref('/locations')
      .once('value')
      .then(data => {
        locations.value = []
        data.forEach(function (snapshot) {
          const location = snapshot.val()
          location.id = snapshot.key
          locations.value.push(location)
        })
      })
  }

  const testees = ref([])

  function getTestees() {
    db.ref('/patients')
      .once('value')
      .then(data => {
        testees.value = []
        data.forEach(function (snapshot) {
          const testee = snapshot.val()
          testee.id = snapshot.key
          testees.value.push(testee)
        })
      })
  }

  const router = useRouter()
  const store = useStore()
  const utc = getUTC(new Date())

  async function createBooking(formData) {
    // create location
    if (!formData.location.id) {
      formData.location.created = utc
      formData.location.modified = utc
      formData.location.active = true

      const newLocationKey = getNewKey('locations')
      await db.ref('locations/' + newLocationKey).set(formData.location)
      formData.location.id = newLocationKey
    }

    // loop through all entered testees
    let count = 0
    for await (const [key, row] of formData.testees.entries()) {
      // format dob
      row.dob = row.dob ? formatDate({ date: row.dob }) : ''
      // add 5 mins to formData.datetime for each patient
      row.datetime = moment(formData.datetime)
        .add(count * 5, 'm')
        .toDate()
      // increment
      count++
      // save to autocomplete db
      if (!formData.testees[key].id) {
        const newPatient = row
        newPatient.created = utc
        newPatient.modified = utc
        newPatient.active = true

        const newPatientKey = getNewKey('patients')
        await db.ref('patients/' + newPatientKey).set(newPatient)
        formData.testees[key].id = newPatientKey
      }
    }

    formData.duration = count * 5 + 30

    formData.user = store.state.user
    formData.created = new Date()
    formData.modified = new Date()
    formData.active = true

    // create booking row
    return await firestore
      .collection('bookings')
      .add(formData)
      .then(docRef => {
        formData.id = docRef.id
        // send email
        onBookingCreate(formData)

        if (redirect) {
          router.push({ name: redirect, params: { id: docRef.id } })
        }
      })
  }

  return {
    locations,
    getLocations,
    testees,
    getTestees,
    createBooking
  }
}

// get all bookings within 8 hours of proposed datetime
// loop through bookings
// compare start time + duration
// if proposed start time falls within that range, show warning
export async function checkAvailability(bookingStart, patients, id) {
  // query db for all bookings +- 8 hours of proposed datetime
  const from = moment(bookingStart).subtract(8, 'hours').toDate()
  const to = moment(bookingStart).add(8, 'hours').toDate()
  // length of proposed booking
  const length = patients ? patients * 5 + 30 : 30
  const bookingEnd = moment(bookingStart).add(length, 'minutes').toDate()
  try {
    const querySnapshot = await firestore
      .collection('bookings')
      .where('datetime', '>=', from)
      .where('datetime', '<=', to)
      .get()

    let isAvailable = true
    querySnapshot.forEach(doc => {
      if (id && id === doc.id) return

      const data = doc.data()
      // start of this booking
      const start = data.datetime.toDate()
      // duration of this booking
      const duration = data.duration ? data.duration : 60
      // end of this booking
      const end = moment(start).add(duration, 'minutes').toDate()
      // it is unavailable if:
      // proposed start time is between this booking ||
      // proposed start time is before the start of this booking,
      // && proposed end time is after start of this booking
      if (
        moment(bookingStart).isBetween(start, end) ||
        (moment(bookingStart).isSameOrBefore(start) &&
          moment(bookingEnd).isSameOrAfter(start))
      ) {
        isAvailable = false
      }
    })
    return isAvailable
  } catch (error) {
    console.error(error.message)
  }
}
